<template>
  <div class="column-filter">
    <gf-button type="primary" size="sm" class="mr-2" @click="print">
      <template slot="icon">
        <span class="svg-icon svg-icon-white svg-icon-sm">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"/>
                <path d="M16,17 L16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 L8,17 L5,17 C3.8954305,17 3,16.1045695 3,15 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,15 C21,16.1045695 20.1045695,17 19,17 L16,17 Z M17.5,11 C18.3284271,11 19,10.3284271 19,9.5 C19,8.67157288 18.3284271,8 17.5,8 C16.6715729,8 16,8.67157288 16,9.5 C16,10.3284271 16.6715729,11 17.5,11 Z M10,14 L10,20 L14,20 L14,14 L10,14 Z" fill="#000000"/>
                <rect fill="#000000" opacity="0.3" x="8" y="2" width="8" height="2" rx="1"/>
            </g>
          </svg>
        </span>
      </template>
      Print
    </gf-button>
    <gf-button type="secondary" size="sm" @click="handleBack">
      <template slot="icon">
        <span class="svg-icon svg-icon-sm">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24"/>
              <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
              <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
            </g>
          </svg>
        </span>
      </template>
      Back
    </gf-button>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/components/_variables.bootstrap.scss';

@media print {
  .column-filter { display: none !important }
  .header { margin-top: 0px !important; margin-bottom: 5px !important; }
  .options { display: none !important; }

  // Outside the el-card

  body {
    overflow: initial !important;
    height: initial;
    background: #fff !important;
  }

  .el-main { overflow: initial !important; }
  .topbar { display: none; }
  .base-container { margin-top: -65px; background: #fff !important; }
  .base-container__row { margin: 0 !important; padding: 0 !important; }
  .base-container__header { display: none !important; }
  .base-container__row { margin: 0px !important; }
  .base-container__row--space-bottom { margin-bottom: 0px !important; }
  .base-container__row--fill-height { margin: 0px !important; }

  // Start of Main Body

  .el-card {
    box-shadow: none !important;
    border-radius: 0px  !important;
    border: none !important;
    background-color: #FFFF;
  }

  .base-card {
    overflow: initial !important;
    page-break-inside: auto !important;
    box-shadow: none !important;
    z-index: 1;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &__header { width: 100%; text-align:center !important; }
  }

  .col { overflow: initial !important; }
  .el-card__body { width: 100%; }

  // Table Page Breaking

  table { page-break-inside:auto !important; }

  th {
    font-size: 10px !important;
  }

  tr td {
    font-size: 10px !important;
    page-break-inside: avoid !important;
    page-break-after:auto !important;
  }

  tr {
    border-bottom: 1px rgba($color: #000, $alpha: 0.45) solid !important;
    margin-bottom: 1px !important;
  }

  thead { display:table-header-group !important; }

  .base-card__description {
    padding-top: 0px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
}

.column-filter {
    display: flex;
    position: absolute;
    top: 40px;
    right: 60px;
    z-index: 1;

  .menu {
    cursor: pointer;
    padding: 6px;
    border-radius: 5.5px;
    background: $primary-light;
    display: flex;
    align-items: center;
    transition: .3s all;

    &:hover {
      background: $primary;

      & > i {
        color: #fff;
      }

      & > span {
        color: #fff !important;
      }
    }

    i {
      transition: .2s all;
      font-size: 20px;
      color: $primary;
    }

    span {
      transition: .2s all;
      color: $primary;
    }
  }
}
</style>

<script>
export default {
  methods: {
    print () {
      document.title = 'Bill Of MateriAls'
      window.print()
    },
    handleBack () {
      this.$router.back()
    }
  }
}
</script>
