<template>
  <gf-base v-loading="loading">
    <el-card class="base-card" :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: '0px', pageBreakInside: 'auto !important'}">
      <Media />
      <el-row class="body-row">
        <el-col style="overflow: initial !important">
          <div class="header text-center mt-6">
            <span class="font-size-lg font-weight-bolder text-dark">{{ company.name }}</span><br>
            <span class="font-size-md font-weight-bolder text-muted">Bill of Materials</span>
          </div>
          <el-row type="flex" class="base-card__description">
            <table class="base-card__description__table" >
              <thead class="thead">
                <tr>
                  <th :class="thprod" style="width: 40%">Product Name</th>
                  <th :class="thcent" style="width: 20%">SKU</th>
                  <th :class="thcent">Unit Price</th>
                  <th :class="thcent">Quantity</th>
                  <th :class="thcent">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in materials" :key="item.id">
                  <td :class="tdprod"><span :class="span">{{ item.product.name }}</span><br/>{{ item.variant.name }}</td>
                  <td :class="tdcent">{{ item.variant.sku }}</td>
                  <td :class="tdleft">{{ $NumberFormat(item.cost) }}</td>
                  <td :class="tdleft">{{ $NumberFormat(item.quantity) }}</td>
                  <td :class="tdleft">{{ $NumberFormat(item.quantity * item.cost) }}</td>
                </tr>
                <tr>
                  <td :class="tdprod" colspan="3"><b>Total</b></td>
                  <td :class="tdleft">{{ $NumberFormat(totalQuantity) }}</td>
                  <td :class="tdleft">{{ $NumberFormat(total) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center"><span class="font-size-xs font-weight-light text-dark"><i>*** Nothing Follows ***</i></span></div>
          </el-row>
        </el-col>
      </el-row>
    </el-card>
  </gf-base>
</template>

<style lang="scss">
.el-popover {
  background-color: rgba(0,0,0,0.4);
  border: none;
  border-radius: 0px;
}

</style>

<style lang="scss" scoped>
.body-row {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.col {
  flex-grow: 1;
  height: 0;
  overflow: auto;
}

.base-card {
  overflow: auto;
  font-family: Poppins, Helvetica, sans-serif !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__sticky {
    padding-top: 20px;
    display: flex;
    position: fixed;
    width: 100%;
    flex-direction: row;
    z-index: 1;

    .el-popover { padding-left: 0px !important; }

    &__preview { display: flex; }

    &__print { display: flex; }
  }

  &__description {
    display: flex;
    box-sizing: border-box;
    padding: 1% 5% 3% 5%;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100%;

    &__table { border-collapse: collapse; }
  }
}
</style>

<script>
import CompanyMaterialService from '@/services/v1/CompanyMaterial'
import CompanyService from '@/services/v1/Company'
import Media from './components/Media.vue'

export default {
  components: {
    Media
  },
  data () {
    return {
      materials: [],
      isVisible: false,
      loading: false,

      company: {
        name: null
      },

      // Styles
      thprod: 'font-size-xs font-weight-bolder text-dark text-left border border-dark p-2',
      thcent: 'font-size-xs font-weight-bolder text-dark text-center border border-dark p-1',
      tdprod: 'font-size-xs font-weight-normal text-dark text-left border border-dark p-2',
      tdcent: 'font-size-xs font-weight-normal text-dark text-center border border-dark p-1',
      tdleft: 'font-size-xs font-weight-normal text-dark text-right border border-dark p-1 pr-4',
      span: 'font-weight-bolder'
    }
  },
  computed: {
    totalQuantity () {
      return this.materials.map(mt => mt.quantity).reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0)
    },
    total () {
      return this.materials.map(mt => mt.quantity * mt.cost).reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0)
    }
  },
  methods: {
    handleFilter () {
      this.isVisible = false
    },
    handleBack () {
      this.$router.back()
    },
    async getMaterials () {
      this.loading = true
      try {
        const cmService = new CompanyMaterialService(this.$route.params.id)
        const result = await cmService.list()
        this.materials = result.data.rows
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    async getCompany () {
      try {
        const cmService = new CompanyService(this.$route.params.id)
        const result = await cmService.get()
        this.company.name = result.data.name
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      }
    }
  },
  async mounted () {
    await this.getCompany()
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Relationship' },
      { title: 'Companies' },
      { title: this.company.name },
      { title: 'Bill of Materials' }
    ])

    this.getMaterials()
  }
}
</script>
